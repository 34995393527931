import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
!function (t, e) {
  e(exports);
}(exports, function (t) {
  "use strict";

  function s(t) {
    var e = u.indexOf(t);
    -1 < e && u.splice(e, 1), u.push(t), p = 0, l && !f && (o = c(i), f = !0);
  }

  function e(t) {
    for (var e = 0; e < u.length; e++) if (t === u[e]) return u[e];

    return null;
  }

  function F() {
    return f;
  }

  function x() {
    return d;
  }

  var o,
      r = "undefined" != typeof self ? self : "undefined" != typeof window ? window : "undefined" != typeof _global ? _global : this || _global || (void 0 !== t ? t : {}),
      n = r.requestAnimationFrame || function (t) {
    return r.setTimeout(t, 50 / 3);
  },
      a = r.cancelAnimationFrame || function (t) {
    return r.clearTimeout(t);
  },
      C = function () {
    if ("undefined" == typeof process || void 0 === process.hrtime || process.versions && void 0 !== process.versions.electron) {
      if (void 0 !== r.performance && void 0 !== r.performance.now) return r.performance.now.bind(r.performance);
      var t = r.performance && r.performance.timing && r.performance.timing.navigationStart ? r.performance.timing.navigationStart : Date.now();
      return function () {
        return Date.now() - t;
      };
    }

    return function () {
      var t = process.hrtime();
      return 1000 * t[0] + t[1] / 1000000;
    };
  }(),
      u = [],
      f = !1,
      l = !1,
      h = [],
      c = n,
      p = 0,
      y = 120,
      d = !0,
      N = function (t) {
    var e = u.indexOf(t);
    -1 !== e && u.splice(e, 1), 0 === u.length && (a(o), f = !1);
  },
      i = function t() {
    var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : C(),
        r = 1 < arguments.length ? arguments[1] : void 0;
    if (y <= p && d) return f = !1, p = 0, a(o), !1;
    l && f ? o = c(t) : function () {
      for (var t = 0; t < h.length; t++) h[t]();
    }(), u.length || p++;

    for (var n = 0, i = u.length; n < i;) u[n++].update(e, r), i > u.length && n--, i = u.length;

    return !0;
  },
      q = {},
      v = {
    Linear: {
      None: function (t) {
        return t;
      }
    },
    Quadratic: {
      In: function (t) {
        return Math.pow(t, 2);
      },
      Out: function (t) {
        return t * (2 - t);
      },
      InOut: function (t) {
        return (t *= 2) < 1 ? 0.5 * Math.pow(t, 2) : -0.5 * (--t * (t - 2) - 1);
      }
    },
    Cubic: {
      In: function (t) {
        return Math.pow(t, 3);
      },
      Out: function (t) {
        return --t * t * t + 1;
      },
      InOut: function (t) {
        return (t *= 2) < 1 ? 0.5 * Math.pow(t, 3) : 0.5 * ((t -= 2) * t * t + 2);
      }
    },
    Quartic: {
      In: function (t) {
        return Math.pow(t, 4);
      },
      Out: function (t) {
        return 1 - --t * t * t * t;
      },
      InOut: function (t) {
        return (t *= 2) < 1 ? 0.5 * Math.pow(t, 4) : -0.5 * ((t -= 2) * t * t * t - 2);
      }
    },
    Quintic: {
      In: function (t) {
        return Math.pow(t, 5);
      },
      Out: function (t) {
        return --t * t * t * t * t + 1;
      },
      InOut: function (t) {
        return (t *= 2) < 1 ? 0.5 * Math.pow(t, 5) : 0.5 * ((t -= 2) * t * t * t * t + 2);
      }
    },
    Sinusoidal: {
      In: function (t) {
        return 1 - Math.cos(t * Math.PI / 2);
      },
      Out: function (t) {
        return Math.sin(t * Math.PI / 2);
      },
      InOut: function (t) {
        return 0.5 * (1 - Math.cos(Math.PI * t));
      }
    },
    Exponential: {
      In: function (t) {
        return 0 === t ? 0 : Math.pow(1024, t - 1);
      },
      Out: function (t) {
        return 1 === t ? 1 : 1 - Math.pow(2, -10 * t);
      },
      InOut: function (t) {
        return 0 === t ? 0 : 1 === t ? 1 : (t *= 2) < 1 ? 0.5 * Math.pow(1024, t - 1) : 0.5 * (2 - Math.pow(2, -10 * (t - 1)));
      }
    },
    Circular: {
      In: function (t) {
        return 1 - Math.sqrt(1 - t * t);
      },
      Out: function (t) {
        return Math.sqrt(1 - --t * t);
      },
      InOut: function (t) {
        return (t *= 2) < 1 ? -0.5 * (Math.sqrt(1 - t * t) - 1) : 0.5 * (Math.sqrt(1 - (t -= 2) * t) + 1);
      }
    },
    Elastic: {
      In: function (t) {
        return 0 === t ? 0 : 1 === t ? 1 : -Math.pow(2, 10 * (t - 1)) * Math.sin(5 * (t - 1.1) * Math.PI);
      },
      Out: function (t) {
        return 0 === t ? 0 : 1 === t ? 1 : Math.pow(2, -10 * t) * Math.sin(5 * (t - 0.1) * Math.PI) + 1;
      },
      InOut: function (t) {
        return 0 === t ? 0 : 1 === t ? 1 : (t *= 2) < 1 ? -0.5 * Math.pow(2, 10 * (t - 1)) * Math.sin(5 * (t - 1.1) * Math.PI) : 0.5 * Math.pow(2, -10 * (t - 1)) * Math.sin(5 * (t - 1.1) * Math.PI) + 1;
      }
    },
    Back: {
      In: function (t) {
        return t * t * (2.70158 * t - 1.70158);
      },
      Out: function (t) {
        return --t * t * (2.70158 * t + 1.70158) + 1;
      },
      InOut: function (t) {
        var e = 2.5949095;
        return (t *= 2) < 1 ? t * t * ((1 + e) * t - e) * 0.5 : 0.5 * ((t -= 2) * t * ((1 + e) * t + e) + 2);
      }
    },
    Bounce: {
      In: function (t) {
        return 1 - v.Bounce.Out(1 - t);
      },
      Out: function (t) {
        var e = 2.75,
            r = 7.5625;
        return t < 1 / e ? r * t * t : t < 2 / e ? r * (t -= 1.5 / e) * t + 0.75 : t < 2.5 / e ? r * (t -= 2.25 / e) * t + 0.9375 : r * (t -= 2.625 / e) * t + 0.984375;
      },
      InOut: function (t) {
        return t < 0.5 ? 0.5 * v.Bounce.In(2 * t) : 0.5 * v.Bounce.Out(2 * t - 1) + 0.5;
      }
    },
    Stepped: {
      steps: function (e) {
        return function (t) {
          return (t * e | 0) / e;
        };
      }
    }
  };

  function m(t) {
    return (m = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    })(t);
  }

  function _(t, e) {
    if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
  }

  function g(t, e) {
    for (var r = 0; r < e.length; r++) {
      var n = e[r];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
    }
  }

  function b(t, e, r) {
    return e && g(t.prototype, e), r && g(t, r), t;
  }

  function T(e, t) {
    var r = Object.keys(e);

    if (Object.getOwnPropertySymbols) {
      var n = Object.getOwnPropertySymbols(e);
      t && (n = n.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), r.push.apply(r, n);
    }

    return r;
  }

  function O(i) {
    for (var t = 1; t < arguments.length; t++) {
      var o = null != arguments[t] ? arguments[t] : {};
      t % 2 ? T(Object(o), !0).forEach(function (t) {
        var e, r, n;
        e = i, n = o[r = t], r in e ? Object.defineProperty(e, r, {
          value: n,
          enumerable: !0,
          configurable: !0,
          writable: !0
        }) : e[r] = n;
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(i, Object.getOwnPropertyDescriptors(o)) : T(Object(o)).forEach(function (t) {
        Object.defineProperty(i, t, Object.getOwnPropertyDescriptor(o, t));
      });
    }

    return i;
  }

  function w(t) {
    return (w = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    })(t);
  }

  function k(t, e) {
    return (k = Object.setPrototypeOf || function (t, e) {
      return t.__proto__ = e, t;
    })(t, e);
  }

  function j(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }

  function A(t, e) {
    return !e || "object" != typeof e && "function" != typeof e ? j(t) : e;
  }

  function S(t, e) {
    return function (t) {
      if (Array.isArray(t)) return t;
    }(t) || function (t, e) {
      if (!(Symbol.iterator in Object(t) || "[object Arguments]" === Object.prototype.toString.call(t))) return;
      var r = [],
          n = !0,
          i = !1,
          o = void 0;

      try {
        for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done) && (r.push(s.value), !e || r.length !== e); n = !0);
      } catch (t) {
        i = !0, o = t;
      } finally {
        try {
          n || null == a.return || a.return();
        } finally {
          if (i) throw o;
        }
      }

      return r;
    }(t, e) || function () {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }();
  }

  var D = 50 / 3,
      U = "_chainedTweens",
      E = "Callback",
      V = "update",
      B = "complete",
      G = "start",
      z = "repeat",
      Q = "reverse",
      P = "restart",
      M = "STRING_PROP",
      R = /\s+|([A-Za-z?().,{}:""[\]#%]+)|([-+]=+)?([-+]+)?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]=?\d+)?/g;

  function I(t) {
    if (t && t.nodeType || void 0 === t || "object" !== m(t)) return t;
    if (Array.isArray(t)) return [].concat(t);
    if ("object" !== m(t)) return t;
    var e = {};

    for (var r in t) e[r] = I(t[r]);

    return e;
  }

  var L = function (t) {
    return isNaN(+t) || ("+" === t[0] || "-" === t[0]) && "=" === t[1] || "" === t || " " === t;
  },
      K = /^#([0-9a-f]{6}|[0-9a-f]{3})$/gi,
      W = function (t, e) {
    var r, n, i;
    3 === e.length && (e = (r = e[0]) + r + (n = e[1]) + n + (i = e[2]) + i);
    var o = parseInt(e, 16);
    return "rgb(" + (r = o >> 16 & 255) + ", " + (n = o >> 8 & 255) + ", " + (i = 255 & o) + ")";
  };

  function H(t) {
    if (t && t.splice && t.isString) return t;
    if ("string" != typeof t) return t;
    if ("=" === t.charAt(1)) return t;
    var e = t.replace(K, W).match(R).map(function (t) {
      return L(t) ? t : +t;
    });
    return e.isString = !0, e;
  }

  function X(r, n, t, e) {
    var i = t[r],
        o = e[r];
    if (i === o) return !0;
    if (Array.isArray(i) && Array.isArray(o) && i.length === o.length) for (var s = 0, a = o.length; s < a; s++) {
      var u = i[s],
          f = o[s];
      u === f || "number" == typeof u && "number" == typeof f || X(s, n[r], i, o);
    }

    if (!("number" == typeof i && "number" == typeof o || i && i.splice && i.isString && o && o.splice && o.isString)) {
      if ("string" == typeof i && Array.isArray(o)) {
        var l = H(i),
            h = o.map(H);
        return t[r] = l, e[r] = h, !0;
      }

      if ("string" == typeof i || "string" == typeof o) {
        var c = Array.isArray(i) && i[0] === M ? i : H(i),
            p = Array.isArray(o) && o[0] === M ? o : H(o);
        if (void 0 === c) return;

        for (var y = 1; y < c.length;) c[y] === p[y] && "string" == typeof c[y - 1] ? (c.splice(y - 1, 2, c[y - 1] + c[y]), p.splice(y - 1, 2, p[y - 1] + p[y])) : y++;

        return c[y = 0] === M && c.shift(), p[0] === M && p.shift(), t[r] = c, e[r] = p, !0;
      }

      if ("object" === m(i) && "object" === m(o)) {
        if (Array.isArray(i) && !i.isString) return i.map(function (t, e) {
          return X(e, n[r], i, o);
        });

        for (var d in o) X(d, n[r], i, o);

        return !0;
      }
    }

    return !1;
  }

  var Y = "rgba(",
      Z = function (t, e, r) {
    var n = 2 < arguments.length && void 0 !== r ? r : "rgb(";
    return "number" == typeof t[e] && (t[e - 1] === n || t[e - 3] === n || t[e - 5] === n);
  };

  function $(t, e, r, n, i, o, s) {
    var a = s ? r : r[t],
        u = s ? n : n[t];
    if (void 0 === u) return a;
    if (void 0 === a || "string" == typeof a || a === u) return u;

    if ("object" === m(a) && "object" === m(u)) {
      if (!a || !u) return e[t];

      if ("object" === m(a) && a && a.isString && u && u.splice && u.isString) {
        for (var f = "", l = 0, h = a.length; l < h; l++) if (a[l] !== u[l] || "number" != typeof a[l] || "number" == typeof u[l]) {
          var c = "number" == typeof a[l] && "string" == typeof u[l] && "=" === u[l][1],
              p = "number" != typeof a[l] ? a[l] : c ? a[l] + parseFloat(u[l][0] + u[l].substr(2)) * i : a[l] + (u[l] - a[l]) * i;
          (Z(a, l) || Z(a, l, Y)) && (p |= 0), f += p, c && 1 === o && (a[l] = a[l] + parseFloat(u[l][0] + u[l].substr(2)));
        } else f += a[l];

        return s || (e[t] = f), f;
      }

      if (Array.isArray(a) && a[0] !== M) for (var y = 0, d = a.length; y < d; y++) a[y] !== u[y] && "string" != typeof e[t] && $(y, e[t], a, u, i, o);else if ("object" === m(a) && a && !a.isString) for (var v in a) a[v] !== u[v] && $(v, e[t], a, u, i, o);
    } else if ("number" == typeof a) {
      var _ = "string" == typeof u;

      e[t] = _ ? a + parseFloat(u[0] + u.substr(2)) * i : a + (u - a) * i, _ && 1 === o && (r[t] = e[t]);
    } else "function" == typeof u && (e[t] = u(i));

    return e[t];
  }

  function J(t, e) {
    var i = t[e],
        o = e.replace(nt, "").split(rt),
        s = o.length - 1,
        a = Array.isArray(t),
        u = "object" === m(t) && !a;
    return u ? (t[e] = null, delete t[e]) : a && t.splice(e, 1), o.reduce(function (t, e, r) {
      a && "." !== e && "[" !== e && (e *= 1);
      var n = "[" === o[r + 1];
      if ("." === e || "[" === e) return "." === e ? a = !(u = !0) : "[" === e && (a = !(u = !1)), t;

      if (void 0 === t[e]) {
        if (a || u) return t[e] = r === s ? i : a || n ? [] : u ? {} : null, u = a = !1, t[e];
      } else if (void 0 !== t[e]) return r === s && (t[e] = i), t[e];

      return t;
    }, t);
  }

  function tt(t) {
    if ("object" === m(t) && t) for (var e in t) if (-1 !== e.indexOf(".") || -1 !== e.indexOf("[")) J(t, e);else if ("object" === m(t[e]) && t[e]) {
      var r = t[e];

      for (var n in r) if (-1 !== n.indexOf(".") || -1 !== n.indexOf("[")) J(r, n);else if ("object" === m(r[n]) && r[n]) {
        var i = r[n];

        for (var o in i) -1 === o.indexOf(".") && -1 === o.indexOf("[") || J(i, o);
      }
    }
    return t;
  }

  var et,
      rt = /([.[])/g,
      nt = /\]/g,
      it = Object.freeze({
    __proto__: null,
    FRAME_MS: D,
    TOO_LONG_FRAME_MS: 250,
    CHAINED_TWEENS: U,
    EVENT_CALLBACK: E,
    EVENT_UPDATE: V,
    EVENT_COMPLETE: B,
    EVENT_START: G,
    EVENT_REPEAT: z,
    EVENT_REVERSE: Q,
    EVENT_PAUSE: "pause",
    EVENT_PLAY: "play",
    EVENT_RESTART: P,
    EVENT_STOP: "stop",
    EVENT_SEEK: "seek",
    STRING_PROP: M,
    NUM_REGEX: R,
    deepCopy: I,
    decomposeString: H,
    decompose: X,
    RGB: "rgb(",
    RGBA: Y,
    isRGBColor: Z,
    recompose: $,
    SET_NESTED: tt
  }),
      ot = {
    Linear: function (t, e, r) {
      var n = t.length - 1,
          i = n * e,
          o = Math.floor(i),
          s = ot.Utils.Linear;
      return e < 0 ? s(t[0], t[1], i, r) : 1 < e ? s(t[n], t[n - 1], n - i, r) : s(t[o], t[n < o + 1 ? n : o + 1], i - o, r);
    },
    Bezier: function (t, e, r) {
      for (var n = ot.Utils.Reset(r), i = t.length - 1, o = Math.pow, s = ot.Utils.Bernstein, a = Array.isArray(n), u = 0; u <= i; u++) if ("number" == typeof n) n += o(1 - e, i - u) * o(e, u) * t[u] * s(i, u);else if (a) for (var f = 0, l = n.length; f < l; f++) "number" == typeof n[f] ? n[f] += o(1 - e, i - u) * o(e, u) * t[u][f] * s(i, u) : n[f] = t[u][f];else if ("object" === m(n)) for (var h in n) "number" == typeof n[h] ? n[h] += o(1 - e, i - u) * o(e, u) * t[u][h] * s(i, u) : n[h] = t[u][h];else if ("string" == typeof n) {
        for (var c = "", p = t[Math.round(i * e)], y = 1, d = p.length; y < d; y++) c += p[y];

        return c;
      }

      return n;
    },
    CatmullRom: function (t, e, r) {
      var n = t.length - 1,
          i = n * e,
          o = Math.floor(i),
          s = ot.Utils.CatmullRom;
      return t[0] === t[n] ? (e < 0 && (o = Math.floor(i = n * (1 + e))), s(t[(o - 1 + n) % n], t[o], t[(o + 1) % n], t[(o + 2) % n], i - o, r)) : e < 0 ? s(t[1], t[1], t[0], t[0], -e, r) : 1 < e ? s(t[n - 1], t[n - 1], t[n], t[n], (0 | e) - e, r) : s(t[o ? o - 1 : 0], t[o], t[n < o + 1 ? n : o + 1], t[n < o + 2 ? n : o + 2], i - o, r);
    },
    Utils: {
      Linear: function (t, e, r, n) {
        if (t === e || "string" == typeof t) {
          if (e.length && e.splice && e.isString) {
            e = "";

            for (var i = 0, o = t.length; i < o; i++) e += t[i];
          }

          return e;
        }

        if ("number" == typeof t) return "function" == typeof t ? t(r) : t + (e - t) * r;

        if ("object" === m(t)) {
          if (void 0 !== t.length) {
            var s = "string" == typeof t[0] || t.isString;

            if (s || t[0] === M) {
              for (var a = "", u = s ? 0 : 1, f = t.length; u < f; u++) {
                var l = 0 === r ? t[u] : 1 !== r && "number" == typeof t[u] ? t[u] + (e[u] - t[u]) * r : e[u];
                (0 < r && r < 1 && Z(t, u) || Z(t, u, Y)) && (l |= 0), a += l;
              }

              return a;
            }

            if (n && n.length && n.splice) for (var h = 0, c = n.length; h < c; h++) n[h] = ot.Utils.Linear(t[h], e[h], r, n[h]);
          } else for (var p in n) n[p] = ot.Utils.Linear(t[p], e[p], r, n[p]);

          return n;
        }
      },
      Reset: function (t) {
        if (Array.isArray(t)) {
          for (var e = 0, r = t.length; e < r; e++) t[e] = ot.Utils.Reset(t[e]);

          return t;
        }

        if ("object" !== m(t)) return "number" == typeof t ? 0 : t;

        for (var n in t) t[n] = ot.Utils.Reset(t[n]);

        return t;
      },
      Bernstein: function (t, e) {
        var r = ot.Utils.Factorial;
        return r(t) / r(e) / r(t - e);
      },
      Factorial: (et = [1], function (t) {
        var e = 1;
        if (et[t]) return et[t];

        for (var r = t; 1 < r; r--) e *= r;

        return et[t] = e;
      }),
      CatmullRom: function (t, e, r, n, i, o) {
        if ("string" == typeof t) return e;

        if ("number" == typeof t) {
          var s = 0.5 * (r - t),
              a = 0.5 * (n - e),
              u = i * i;
          return (2 * e - 2 * r + s + a) * (i * u) + (-3 * e + 3 * r - 2 * s - a) * u + s * i + e;
        }

        if ("object" === m(t)) {
          if (void 0 !== t.length) {
            if (t[0] === M) {
              for (var f = "", l = 1, h = t.length; l < h; l++) {
                var c = "number" == typeof t[l] ? ot.Utils.CatmullRom(t[l], e[l], r[l], n[l], i) : n[l];
                (Z(t, l) || Z(t, l, Y)) && (c |= 0), f += c;
              }

              return f;
            }

            for (var p = 0, y = o.length; p < y; p++) o[p] = ot.Utils.CatmullRom(t[p], e[p], r[p], n[p], i, o[p]);
          } else for (var d in o) o[d] = ot.Utils.CatmullRom(t[d], e[d], r[d], n[d], i, o[d]);

          return o;
        }
      }
    }
  },
      st = {};

  function at(t, e, r) {
    if (!t || !t.nodeType) return e;
    var n = t.queueID || "q_" + Date.now();
    t.queueID || (t.queueID = n);
    var i = st[n];

    if (i) {
      if (i.object === e && t === i.tween.node && r._startTime === i.tween._startTime) N(i.tween);else if ("object" === m(e) && e && i.object) {
        for (var o in e) o in i.object && (r._startTime === i.tween._startTime ? delete i.object[o] : i.propNormaliseRequired = !0);

        Object.assign(i.object, e);
      }
      return i.object;
    }

    return "object" === m(e) && e ? (st[n] = {
      tween: r,
      object: e,
      propNormaliseRequired: !1
    }, st[n].object) : e;
  }

  function ut(t, e, r) {
    return e ? t ? "undefined" != typeof window && t === window || "undefined" != typeof document && t === document ? [t] : "string" == typeof t ? !!document.querySelectorAll && document.querySelectorAll(t) : Array.isArray(t) ? t : t.nodeType ? [t] : r ? t : [] : null : t ? "undefined" != typeof window && t === window || "undefined" != typeof document && t === document ? t : "string" == typeof t ? !!document.querySelector && document.querySelector(t) : Array.isArray(t) ? t[0] : t.nodeType || r ? t : null : null;
  }

  var ft = 0,
      lt = v.Linear.None,
      ht = function () {
    function L(t, e) {
      return _(this || _global, L), (this || _global).id = ft++, !t || "object" !== m(t) || e || t.nodeType ? t && (t.nodeType || t.length || "string" == typeof t) && (t = (this || _global).node = ut(t), e = (this || _global).object = at(t, e, this || _global)) : (e = (this || _global).object = t, t = null), (this || _global)._valuesEnd = null, (this || _global)._valuesStart = Array.isArray(e) ? [] : {}, (this || _global)._duration = 1000, (this || _global)._easingFunction = lt, (this || _global)._easingReverse = lt, (this || _global)._interpolationFunction = ot.Linear, (this || _global)._startTime = 0, (this || _global)._initTime = 0, (this || _global)._delayTime = 0, (this || _global)._repeat = 0, (this || _global)._r = 0, (this || _global)._isPlaying = !1, (this || _global)._yoyo = !1, (this || _global)._reversed = !1, (this || _global)._onStartCallbackFired = !1, (this || _global)._pausedTime = null, (this || _global)._isFinite = !0, (this || _global)._maxListener = 15, (this || _global)._chainedTweensCount = 0, (this || _global)._prevTime = null, this || _global;
    }

    return b(L, null, [{
      key: "fromTo",
      value: function (t, e, r, n) {
        var i = 3 < arguments.length && void 0 !== n ? n : {};
        i.quickRender = i.quickRender ? i.quickRender : !r;
        var o = new L(t, e).to(r, i);
        return i.quickRender && (o.render().update(o._startTime), o._rendered = !1, o._onStartCallbackFired = !1), o;
      }
    }, {
      key: "to",
      value: function (t, e, r) {
        return L.fromTo(t, null, e, r);
      }
    }, {
      key: "from",
      value: function (t, e, r) {
        return L.fromTo(t, e, null, r);
      }
    }]), b(L, [{
      key: "setMaxListener",
      value: function (t) {
        var e = 0 < arguments.length && void 0 !== t ? t : 15;
        return (this || _global)._maxListener = e, this || _global;
      }
    }, {
      key: "on",
      value: function (t, e) {
        for (var r = (this || _global)._maxListener, n = t + E, i = 0; i < r; i++) {
          var o = n + i;

          if (!(this || _global)[o]) {
            (this || _global)[o] = e;
            break;
          }
        }

        return this || _global;
      }
    }, {
      key: "once",
      value: function (t, i) {
        for (var o = this || _global, e = (this || _global)._maxListener, r = t + E, n = function (t) {
          var n = r + t;
          if (!o[n]) return o[n] = function () {
            for (var t = arguments.length, e = new Array(t), r = 0; r < t; r++) e[r] = arguments[r];

            i.apply(o, e), o[n] = null;
          }, "break";
        }, s = 0; s < e; s++) {
          if ("break" === n(s)) break;
        }

        return this || _global;
      }
    }, {
      key: "off",
      value: function (t, e) {
        for (var r = (this || _global)._maxListener, n = t + E, i = 0; i < r; i++) {
          var o = n + i;
          (this || _global)[o] === e && ((this || _global)[o] = null);
        }

        return this || _global;
      }
    }, {
      key: "emit",
      value: function (t, e, r, n) {
        var i = (this || _global)._maxListener,
            o = t + E;
        if (!(this || _global)[o + 0]) return this || _global;

        for (var s = 0; s < i; s++) {
          var a = o + s;
          (this || _global)[a] && this[a](e, r, n);
        }

        return this || _global;
      }
    }, {
      key: "isPlaying",
      value: function () {
        return (this || _global)._isPlaying;
      }
    }, {
      key: "isStarted",
      value: function () {
        return (this || _global)._onStartCallbackFired;
      }
    }, {
      key: "reverse",
      value: function (t) {
        var e = (this || _global)._reversed;
        return (this || _global)._reversed = void 0 !== t ? t : !e, this || _global;
      }
    }, {
      key: "reversed",
      value: function () {
        return (this || _global)._reversed;
      }
    }, {
      key: "pause",
      value: function () {
        return (this || _global)._isPlaying ? ((this || _global)._isPlaying = !1, N(this || _global), (this || _global)._pausedTime = C(), this.emit("pause", (this || _global).object)) : this || _global;
      }
    }, {
      key: "play",
      value: function () {
        return (this || _global)._isPlaying ? this || _global : ((this || _global)._isPlaying = !0, (this || _global)._startTime += C() - (this || _global)._pausedTime, (this || _global)._initTime = (this || _global)._startTime, s(this || _global), (this || _global)._pausedTime = C(), this.emit("play", (this || _global).object));
      }
    }, {
      key: "restart",
      value: function () {
        return (this || _global)._repeat = (this || _global)._r, this.reassignValues(), s(this || _global), this.emit(P, (this || _global).object);
      }
    }, {
      key: "seek",
      value: function (t, e) {
        var r = (this || _global)._duration,
            n = (this || _global)._initTime,
            i = (this || _global)._startTime,
            o = (this || _global)._reversed,
            s = n + t;
        return (this || _global)._isPlaying = !0, s < i && n <= i && ((this || _global)._startTime -= r, (this || _global)._reversed = !o), this.update(t, !1), this.emit("seek", t, (this || _global).object), e ? this || _global : this.pause();
      }
    }, {
      key: "duration",
      value: function (t) {
        return (this || _global)._duration = "function" == typeof t ? t((this || _global)._duration) : t, this || _global;
      }
    }, {
      key: "to",
      value: function (t, e) {
        var r = 1 < arguments.length && void 0 !== e ? e : 1000;
        if ((this || _global)._valuesEnd = t, "number" == typeof r || "function" == typeof r) (this || _global)._duration = "function" == typeof r ? r((this || _global)._duration) : r;else if ("object" === m(r)) for (var n in r) if ("function" == typeof (this || _global)[n]) {
          var i = S(Array.isArray(r[n]) ? r[n] : [r[n]], 4),
              o = i[0],
              s = void 0 === o ? null : o,
              a = i[1],
              u = void 0 === a ? null : a,
              f = i[2],
              l = void 0 === f ? null : f,
              h = i[3],
              c = void 0 === h ? null : h;
          this[n](s, u, l, c);
        }
        return this || _global;
      }
    }, {
      key: "render",
      value: function () {
        if ((this || _global)._rendered) return this || _global;
        var t = (this || _global)._valuesStart,
            e = (this || _global)._valuesEnd,
            r = (this || _global).object,
            n = (this || _global).node,
            i = (this || _global).InitialValues;

        if (tt(r), tt(e), n && n.queueID && st[n.queueID]) {
          var o = st[n.queueID];

          if (o.propNormaliseRequired && o.tween !== (this || _global)) {
            for (var s in e) o.tween._valuesEnd[s];

            o.normalisedProp = !0, o.propNormaliseRequired = !1;
          }
        }

        if (n && i && (r && 0 !== Object.keys(r).length ? e && 0 !== Object.keys(e).length || (e = (this || _global)._valuesEnd = i(n, r)) : r = (this || _global).object = at(n, i(n, e), this || _global)), !t.processed) {
          for (var a in e) {
            var u = r && r[a] && I(r[a]),
                f = e[a];

            if (!(q[a] && q[a].init && (q[a].init.call(this || _global, u, f, a, r), void 0 === u && t[a] && (u = t[a]), q[a].skipProcess)) && !("number" == typeof u && isNaN(u) || null === u || null === f || !1 === u || !1 === f || void 0 === u || void 0 === f || u === f)) {
              if (t[a] = u, Array.isArray(f)) {
                if (Array.isArray(u)) f.isString && r[a].isString && !u.isString ? u.isString = !0 : X(a, r, t, e);else {
                  f.unshift(u);

                  for (var l = 0, h = f.length; l < h; l++) "string" == typeof f[l] && (f[l] = H(f[l]));
                }
              } else X(a, r, t, e);
              "number" != typeof u || "string" != typeof f || f[1];
            }
          }

          t.processed = !0;
        }

        return L.Renderer && (this || _global).node && L.Renderer.init && (L.Renderer.init.call(this || _global, r, t, e), (this || _global).__render = !0), (this || _global)._rendered = !0, this || _global;
      }
    }, {
      key: "start",
      value: function (t) {
        return (this || _global)._startTime = void 0 !== t ? "string" == typeof t ? C() + parseFloat(t) : t : C(), (this || _global)._startTime += (this || _global)._delayTime, (this || _global)._initTime = (this || _global)._prevTime = (this || _global)._startTime, (this || _global)._onStartCallbackFired = !1, (this || _global)._rendered = !1, (this || _global)._isPlaying = !0, s(this || _global), this || _global;
      }
    }, {
      key: "stop",
      value: function () {
        var t = (this || _global)._isPlaying,
            e = (this || _global)._isFinite,
            r = (this || _global).object,
            n = (this || _global)._startTime,
            i = (this || _global)._duration,
            o = (this || _global)._r,
            s = (this || _global)._yoyo,
            a = (this || _global)._reversed;
        if (!t) return this || _global;
        var u = e ? (o + 1) % 2 == 1 : !a;
        return (this || _global)._reversed = !1, s && u ? this.update(n) : this.update(n + i), N(this || _global), this.emit("stop", r);
      }
    }, {
      key: "delay",
      value: function (t) {
        return (this || _global)._delayTime = "function" == typeof t ? t((this || _global)._delayTime) : t, this || _global;
      }
    }, {
      key: "chainedTweens",
      value: function () {
        if ((this || _global)._chainedTweensCount = arguments.length, !(this || _global)._chainedTweensCount) return this || _global;

        for (var t = 0, e = (this || _global)._chainedTweensCount; t < e; t++) (this || _global)[U + t] = arguments[t];

        return this || _global;
      }
    }, {
      key: "repeat",
      value: function (t) {
        return (this || _global)._repeat = (this || _global)._duration ? "function" == typeof t ? t((this || _global)._repeat) : t : 0, (this || _global)._r = (this || _global)._repeat, (this || _global)._isFinite = isFinite(t), this || _global;
      }
    }, {
      key: "reverseDelay",
      value: function (t) {
        return (this || _global)._reverseDelayTime = "function" == typeof t ? t((this || _global)._reverseDelayTime) : t, this || _global;
      }
    }, {
      key: "yoyo",
      value: function (t, e) {
        return (this || _global)._yoyo = "function" == typeof t ? t((this || _global)._yoyo) : null === t ? (this || _global)._yoyo : t, t || ((this || _global)._reversed = !1), (this || _global)._easingReverse = e || null, this || _global;
      }
    }, {
      key: "easing",
      value: function (t) {
        return (this || _global)._easingFunction = t, this || _global;
      }
    }, {
      key: "interpolation",
      value: function (t) {
        return "function" == typeof t && ((this || _global)._interpolationFunction = t), this || _global;
      }
    }, {
      key: "reassignValues",
      value: function (t) {
        var e = (this || _global)._valuesStart,
            r = (this || _global).object,
            n = (this || _global)._delayTime;

        for (var i in (this || _global)._isPlaying = !0, (this || _global)._startTime = void 0 !== t ? t : C(), (this || _global)._startTime += n, (this || _global)._reversed = !1, s(this || _global), e) {
          var o = e[i];
          r[i] = o;
        }

        return this || _global;
      }
    }, {
      key: "update",
      value: function (t, e, r) {
        var n,
            i,
            o,
            s = (this || _global)._onStartCallbackFired,
            a = (this || _global)._easingFunction,
            u = (this || _global)._interpolationFunction,
            f = (this || _global)._easingReverse,
            l = (this || _global)._repeat,
            h = (this || _global)._delayTime,
            c = (this || _global)._reverseDelayTime,
            p = (this || _global)._yoyo,
            y = (this || _global)._reversed,
            d = (this || _global)._startTime,
            v = (this || _global)._prevTime,
            _ = (this || _global)._duration,
            m = (this || _global)._valuesStart,
            g = (this || _global)._valuesEnd,
            b = (this || _global).object,
            T = (this || _global)._isFinite,
            O = (this || _global)._isPlaying,
            w = (this || _global).__render,
            k = (this || _global)._chainedTweensCount,
            j = 0;

        if (_) {
          var A = (t = void 0 !== t ? t : C()) - v;
          if ((this || _global)._prevTime = t, 250 < A && F() && x() && (t -= A - D), !O || t < d && !r) return !0;
          n = 1 < (n = (t - d) / _) ? 1 : n, n = y ? 1 - n : n;
        } else n = 1, l = 0;

        if (s || ((this || _global)._rendered || (this.render(), (this || _global)._rendered = !0), this.emit(G, b), (this || _global)._onStartCallbackFired = !0), i = y && f || a, !b) return !0;

        for (o in g) {
          var S = m[o];

          if (null != S || q[o] && q[o].update) {
            var E = g[o],
                P = i[o] ? i[o](n) : ("function" == typeof i ? i : lt)(n),
                M = u[o] ? u[o] : "function" == typeof u ? u : ot.Linear;
            "number" == typeof E ? b[o] = S + (E - S) * P : !Array.isArray(E) || E.isString || Array.isArray(S) ? E && E.update ? E.update(P) : "function" == typeof E ? b[o] = E(P) : "string" == typeof E && "number" == typeof S ? b[o] = S + parseFloat(E[0] + E.substr(2)) * P : $(o, b, m, g, P, n) : b[o] = M(E, P, b[o]), q[o] && q[o].update && q[o].update.call(this || _global, b[o], S, E, P, n, o), j++;
          }
        }

        if (!j) return N(this || _global), !1;

        if (w && L.Renderer && L.Renderer.update && L.Renderer.update.call(this || _global, b, n), this.emit(V, b, n, t), 1 === n || y && 0 === n) {
          if (0 < l && 0 < _) {
            if (T && (this || _global)._repeat--, p) (this || _global)._reversed = !y;else for (o in g) {
              var R = g[o];
              "string" == typeof R && "number" == typeof m[o] && (m[o] += parseFloat(R[0] + R.substr(2)));
            }
            return this.emit(p && !y ? Q : z, b), (this || _global)._startTime = y && c ? t - c : t + h, !0;
          }

          if (e || ((this || _global)._isPlaying = !1, N(this || _global), ft--), this.emit(B, b), (this || _global)._repeat = (this || _global)._r, k) for (var I = 0; I < k; I++) (this || _global)[U + I].start(t + _);
          return !1;
        }

        return !0;
      }
    }]), L;
  }(),
      ct = function () {
    function t() {
      _(this || _global, t), (this || _global).totalTime = 0, (this || _global).labels = [], (this || _global).offsets = [];
    }

    return b(t, [{
      key: "parseLabel",
      value: function (t, e) {
        var r = (this || _global).offsets,
            n = (this || _global).labels,
            i = n.indexOf(t);

        if ("string" == typeof t && -1 !== t.indexOf("=") && !e && -1 === i) {
          var o = t.substr(t.indexOf("=") - 1, 2),
              s = t.split(o);
          e = 2 === s.length ? o + s[1] : null, t = s[0], i = n.indexOf(t);
        }

        if (-1 !== i && t) {
          var a = r[i] || 0;
          if ("number" == typeof e) a = e;else if ("string" == typeof e && -1 !== e.indexOf("=")) {
            var u = e.charAt(0);
            e = Number(e.substr(2)), "+" === u || "-" === u ? a += parseFloat(u + e) : "*" === u ? a *= e : "/" === u ? a /= e : "%" === u && (a *= e / 100);
          }
          return a;
        }

        return "number" == typeof e ? e : 0;
      }
    }, {
      key: "addLabel",
      value: function (t, e) {
        return (this || _global).labels.push(t), (this || _global).offsets.push(this.parseLabel(t, e)), this || _global;
      }
    }, {
      key: "setLabel",
      value: function (t, e) {
        var r = (this || _global).labels.indexOf(t);

        return -1 !== r && (this || _global).offsets.splice(r, 1, this.parseLabel(t, e)), this || _global;
      }
    }, {
      key: "eraseLabel",
      value: function (t) {
        var e = (this || _global).labels.indexOf(t);

        return -1 !== e && ((this || _global).labels.splice(e, 1), (this || _global).offsets.splice(e, 1)), this || _global;
      }
    }]), t;
  }(),
      pt = 0,
      yt = function () {
    function r(t) {
      var e;
      return _(this || _global, r), (e = A(this || _global, w(r).call(this || _global)))._duration = 0, e._startTime = t && void 0 !== t.startTime ? t.startTime : C(), e._tweens = [], e.elapsed = 0, e._id = pt++, e._defaultParams = t, e.position = new ct(), e.position.addLabel("afterLast", e._duration), e.position.addLabel("afterInit", e._startTime), e._onStartCallbackFired = !1, A(e, j(e));
    }

    return function (t, e) {
      if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
      t.prototype = Object.create(e && e.prototype, {
        constructor: {
          value: t,
          writable: !0,
          configurable: !0
        }
      }), e && k(t, e);
    }(r, ht), b(r, [{
      key: "mapTotal",
      value: function (t) {
        return t.call(this || _global, (this || _global)._tweens), this || _global;
      }
    }, {
      key: "timingOrder",
      value: function (t) {
        var r = t((this || _global)._tweens.map(function (t) {
          return t._startTime;
        }));
        return (this || _global)._tweens.map(function (t, e) {
          t._startTime = r[e];
        }), this || _global;
      }
    }, {
      key: "getTiming",
      value: function (t, e, r, n) {
        var i = 3 < arguments.length && void 0 !== n ? n : 0;

        if ("reverse" === t) {
          var o = r.stagger,
              s = (o || 0) * (e.length - 1);
          return e.map(function (t, e) {
            return s - (o || 0) * e + i;
          });
        }

        if ("async" === t) return e.map(function (t) {
          return i;
        });

        if ("sequence" === t || "delayed" === t) {
          var a = r.stagger;
          return a = a || (r.duration || 1000) / (e.length - 1), e.map(function (t, e) {
            return a * e + i;
          });
        }

        if ("oneByOne" === t) return e.map(function (t) {
          return r.duration;
        });

        if ("shuffle" === t) {
          var u = r.stagger;
          return function (t) {
            var e, r, n;

            for (n = t.length; n; --n) e = Math.floor(Math.random() * n), r = t[n - 1], t[n - 1] = t[e], t[e] = r;

            return t;
          }(e.map(function (t, e) {
            return (u || 0) * e + i;
          }));
        }

        var f = r.stagger;
        return e.map(function (t, e) {
          return (f || 0) * e + i;
        });
      }
    }, {
      key: "fromTo",
      value: function (t, e, r, n) {
        if ((t = ut(t, !0, !0)) && t.length) {
          (this || _global)._defaultParams && (n = n ? O({}, (this || _global)._defaultParams, {}, n) : (this || _global)._defaultParams);

          for (var i, o = n.label, s = "number" == typeof o ? o : (this || _global).position.parseLabel(void 0 !== o ? o : "afterLast", null), a = this.getTiming(n.mode, t, n, s), u = 0, f = t.length; u < f; u++) i = t[u], this.add(ht.fromTo(i, "function" == typeof e ? e(u, t.length) : "object" === m(e) && e ? O({}, e) : null, "function" == typeof r ? r(u, t.length) : r, "function" == typeof n ? n(u, t.length) : n), a[u]);
        }

        return this.start();
      }
    }, {
      key: "from",
      value: function (t, e, r) {
        return this.fromTo(t, e, null, r);
      }
    }, {
      key: "to",
      value: function (t, e, r) {
        return this.fromTo(t, null, e, r);
      }
    }, {
      key: "addLabel",
      value: function (t, e) {
        return (this || _global).position.addLabel(t, e), this || _global;
      }
    }, {
      key: "map",
      value: function (t) {
        for (var e = 0, r = (this || _global)._tweens.length; e < r; e++) {
          var n = (this || _global)._tweens[e];
          t(n, e), (this || _global)._duration = Math.max((this || _global)._duration, n._duration + n._startTime);
        }

        return this || _global;
      }
    }, {
      key: "add",
      value: function (t, e) {
        var r = this || _global;
        if (Array.isArray(t)) return t.map(function (t) {
          r.add(t, e);
        }), this || _global;
        "object" !== m(t) || t instanceof ht || (t = new ht(t.from).to(t.to, t));
        var n = (this || _global)._defaultParams,
            i = (this || _global)._duration;
        if (n) for (var o in n) "function" == typeof t[o] && t[o](n[o]);
        var s = "number" == typeof e ? e : (this || _global).position.parseLabel(void 0 !== e ? e : "afterLast", null);
        return t._startTime = Math.max((this || _global)._startTime, t._delayTime, s), t._delayTime = s, t._isPlaying = !0, (this || _global)._duration = Math.max(i, Math.max(t._startTime + t._delayTime, t._duration)), (this || _global)._tweens.push(t), (this || _global).position.setLabel("afterLast", (this || _global)._duration), this || _global;
      }
    }, {
      key: "restart",
      value: function () {
        return (this || _global)._startTime += C(), s(this || _global), this.emit(P);
      }
    }, {
      key: "easing",
      value: function (e) {
        return this.map(function (t) {
          return t.easing(e);
        });
      }
    }, {
      key: "interpolation",
      value: function (e) {
        return this.map(function (t) {
          return t.interpolation(e);
        });
      }
    }, {
      key: "update",
      value: function (t) {
        var e,
            r = (this || _global)._tweens,
            n = (this || _global)._duration,
            i = (this || _global)._reverseDelayTime,
            o = (this || _global)._startTime,
            s = (this || _global)._reversed,
            a = (this || _global)._yoyo,
            u = (this || _global)._repeat,
            f = (this || _global)._isFinite,
            l = (this || _global)._isPlaying,
            h = (this || _global)._prevTime,
            c = (this || _global)._onStartCallbackFired,
            p = (t = void 0 !== t ? t : C()) - h;
        if ((this || _global)._prevTime = t, 250 < p && F() && x() && (t -= p - D), !l || t < o) return !0;
        e = 1 < (e = (t - o) / n) ? 1 : e, e = s ? 1 - e : e, (this || _global).elapsed = e, c || (this.emit(G), (this || _global)._onStartCallbackFired = !0);

        for (var y = t - o, d = s ? n - y : y, v = 0; v < r.length;) r[v].update(d), v++;

        if (this.emit(V, e, y), 1 === e || s && 0 === e) {
          if (u) {
            for (f && (this || _global)._repeat--, this.emit(s ? Q : z), a && ((this || _global)._reversed = !s, this.timingOrder(function (t) {
              return t.reverse();
            })), (this || _global)._startTime = s && i ? t + i : t, v = 0; v < r.length;) r[v].reassignValues(t), v++;

            return !0;
          }

          return this.emit(B), (this || _global)._repeat = (this || _global)._r, N(this || _global), (this || _global)._isPlaying = !1;
        }

        return !0;
      }
    }, {
      key: "progress",
      value: function (t) {
        return void 0 !== t ? this.update(t * (this || _global)._duration) : (this || _global).elapsed;
      }
    }]), r;
  }();

  t.Easing = v, t.FrameThrottle = function (t) {
    y = 1.05 * (0 < arguments.length && void 0 !== t ? t : 120);
  }, t.Interpolation = ot, t.Interpolator = function (i, o) {
    var s = Array.isArray(i) && !i.isString,
        a = "string" == typeof i ? i : s ? i.slice() : O({}, i);
    if (s) for (var t = 0, e = i.length; t < e; t++) i[t] === o[t] && "number" == typeof i[t] && "number" != typeof o[t] || X(t, a, i, o);else if ("object" === m(i)) for (var r in i) i[r] === o[r] && "number" == typeof i[r] && "number" != typeof o[r] || X(r, a, i, o);else if ("string" == typeof i) {
      i = H(i), o = H(o);

      for (var n = 1; n < i.length;) i[n] === o[n] && "string" == typeof i[n - 1] ? (i.splice(n - 1, 2, i[n - 1] + i[n]), o.splice(n - 1, 2, o[n - 1] + o[n])) : n++;
    }
    return function (t) {
      if (s) for (var e = 0, r = i.length; e < r; e++) $(e, a, i, o, t);else if ("object" === m(a)) for (var n in i) $(n, a, i, o, t);else "string" == typeof a && (a = $(0, 0, i, o, t, t, !0));
      return a;
    };
  }, t.Plugins = q, t.Selector = ut, t.Timeline = yt, t.ToggleLagSmoothing = function (t) {
    d = !(0 < arguments.length && void 0 !== t) || t;
  }, t.Tween = ht, t.add = s, t.autoPlay = function (t) {
    l = t;
  }, t.get = e, t.getAll = function () {
    return u;
  }, t.has = function (t) {
    return null !== e(t);
  }, t.isRunning = F, t.now = C, t.onRequestTick = function (t) {
    h.push(t);
  }, t.onTick = function (t) {
    return u.push({
      update: t
    });
  }, t.remove = N, t.removeAll = function () {
    u.length = 0, a(o), f = !1;
  }, t.update = i, t.utils = it, Object.defineProperty(t, "__esModule", {
    value: !0
  });
});
export default exports;